import React from 'react';
import { useGetV2TicketById } from './useGetV2TicketById.hook';
import { InfoCardRow } from '../common/components';
import { Box, Card, CardContent, CircularProgress, Grid, Typography } from '@material-ui/core';
import routes from '../../utils/routes';
import { formatDateTime, formatEuro, formatForint } from '../../utils/formatters';

export const formatReadableV2TicketStatus = status => {
  return status === 'ReservedForOnlinePayment'
    ? 'Reserved for online payment'
    : status === 'FinalizedByOnlinePayment'
    ? 'Finalized by online payment'
    : status === 'ReservedForOfficePayment'
    ? 'Reserved for office payment'
    : status === 'FinalizedByOfficeEmployee'
    ? 'Finalized by office employee'
    : status === 'ExpiredByCronJob'
    ? 'Expired'
    : status;
};

function V2TicketPage({
  match: {
    params: { id },
  },
}) {
  const { data, isLoading, isSuccess, isError } = useGetV2TicketById(id);

  const { ticket } = data || {};
  const rows = [
    {
      label: 'Type',
      value: ticket?.product?.name,
    },
    {
      label: 'Customer',
      value: routes.customer.createLink(ticket?.customer.id, ticket?.customer.fullName),
    },
    {
      label: 'Event',
      value: routes.event.createLink(ticket?.event._id, ticket?.event.name),
    },
    {
      label: 'Price (HUF)',
      value: formatForint(Math.round(ticket?.product?.priceInHuf / 10) * 10),
    },
    {
      label: 'Price (EUR)',
      value: formatEuro(ticket?.product?.priceInEur),
    },
    {
      label: 'Status',
      value: formatReadableV2TicketStatus(ticket?.status),
    },
    {
      label: 'Sold at',
      value: formatDateTime(ticket?.createdDate || new Date()),
    },
  ];
  return (
    <Box style={{ padding: '8px', margin: '8px' }}>
      {isLoading && <CircularProgress />}
      {isSuccess && (
        <Card>
          <CardContent>
            <Typography variant="h3" component="h1" gutterBottom>
              {ticket?.ticketNumber}
            </Typography>
            {rows.map(({ label, value }) => (
              <Grid item key={label} style={{ margin: '8px' }}>
                <InfoCardRow label={label} value={value} />
              </Grid>
            ))}
          </CardContent>
        </Card>
      )}
    </Box>
  );
}

export default V2TicketPage;

import React from 'react';

import routes from '../../../../utils/routes';
import Privileges from '../../../../utils/access-control/privileges.json';
import { insert } from '../../../../utils/helpers';

import { GET_USERS, GET_OTHER_USERS } from '../../graphql';

import { useCheckPrivilege } from '../../../lib/hooks';

import { Table, CollectionTable } from '../../../common/components';
import { formatForint } from '../../../../utils/formatters';

function UsersTable() {
  const access = useCheckPrivilege(Privileges.READ_ANY_USER_SALES_DATA);

  let columns = [
    {
      name: 'fullName',
      label: 'Full Name',
      options: {
        filterType: 'textField',
        renderCell: (value, user) => routes.user.createLink(user, value),
      },
    },
    {
      name: 'email',
      label: 'Email',
      options: {
        renderCell: (value, user) => routes.user.createLink(user, value),
        filterType: 'textField',
      },
    },
    {
      name: 'role.name',
      label: 'Role',
      options: {
        filterType: 'multiselect',
        filter: false,
      },
    },
    {
      name: 'isActive',
      label: 'Status',
      booleanValues: ['Active', 'Inactive'],
      options: {
        renderCell: isActive => (isActive ? 'Active' : 'Inactive'),
        filterType: 'boolean',
      },
    },
    {
      name: 'isInactive',
      booleanValues: ['Inactive'],
      options: {
        renderCell: (row) => {
          return row;
        },
        filterType: 'boolean',
        display: false,
        viewColumns: false
      },
    },
  ];
  if (access) {
    columns = insert(
      columns,
      5,
      {
        name: 'account.sales.todayInHuf',
        label: 'Sales Today made in HUF',
        type: 'currency',
      },
      {
        name: 'account.sales.todayInEur',
        label: 'Sales Today made in EUR',
        type: 'currency_eur',
      },
      {
        name: 'account.balance',
        label: 'Personal Account Balance (HUF)',
        //type: 'currency',
        options: {
          renderCell: (value, { account }) =>
            account.balanceInHuf > 0
              ? formatForint(Math.round(account?.balanceInHuf / 10) * 10)
              : account?.balance >= 0
              ? formatForint(Math.round(account?.balance / 10) * 10)
              : '-',
        },
      },
      {
        name: 'account.balanceInEur',
        label: 'Personal Account Balance (EUR)',
        type: 'currency_eur',
      },
    );
  }

  return (
    <CollectionTable>
      <Table
        title="Users List"
        collectionName="users"
        query={access ? GET_USERS : GET_OTHER_USERS}
        columns={columns}
      />
    </CollectionTable>
  );
}

export default UsersTable;

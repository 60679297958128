import { Box, Tab, Tabs, Typography } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { CheckElbCard } from '../check-elb-card/components';
import { ElbCardReading } from '../elb-card-reading/components';
import { TabPanel } from '../common/components';
import { useHistory } from 'react-router';
import CheckingSearchBar from './CheckingSearchBar.component';
import { QrCodeReading } from '../qr-code-reading/components/QrCodeReading';

const TABS = {
  TAB_SEARCH_BAR_CHECK: 'TAB_SEARCH_BAR_CHECK',
  TAB_SEARCH_CHECK: 'TABS_SEARCH_CHECK',
  TAB_BARCODE_READ: 'TAB_BARCODE_READ',
  TAB_QRCODE_READER: 'TAB_QRCODE_READER',
};

function CheckingPage() {
  const history = useHistory();
  const searchParams = new URLSearchParams(history.location.search);

  const [tabValue, setTabValue] = useState(
    searchParams.get('opened-tab') || TABS.TAB_SEARCH_BAR_CHECK,
  );

  const handleTabChange = (event, newValue) => {
    console.log('handleTabChange', newValue);
    const pathname = history.location.pathname;
    searchParams.set('opened-tab', newValue);
    history.push({ pathname, search: searchParams.toString() });
    setTabValue(newValue);
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Search Bar Check" value={TABS.TAB_SEARCH_BAR_CHECK} />
          <Tab label="Search Check" value={TABS.TAB_SEARCH_CHECK} />
          <Tab label="Barcode Reader" value={TABS.TAB_BARCODE_READ} />
          <Tab label="QR Code Reader" value={TABS.TAB_QRCODE_READER} />
        </Tabs>
      </Box>
      <TabPanel value={tabValue} index={TABS.TAB_SEARCH_BAR_CHECK}>
        <Typography variant="h3">Search bar check</Typography>
        <CheckingSearchBar />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_SEARCH_CHECK}>
        <Typography variant="h3">Check ELB Card</Typography>
        <CheckElbCard />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_BARCODE_READ}>
        <Typography variant="h3">Card Reading Last 12 Hours</Typography>
        <ElbCardReading />
      </TabPanel>
      <TabPanel value={tabValue} index={TABS.TAB_QRCODE_READER} style={{ width: '100%' }}>
        <Box style={{ display: 'flex', justifyContent: 'center', marginRight: "180px" }}>
          <Typography variant="h3">QR Code Reader</Typography>
        </Box>
        <QrCodeReading />
      </TabPanel>
    </Box>
  );
}

export default CheckingPage;

import React from 'react';
import * as PropTypes from 'prop-types';
import { IconButton } from '@material-ui/core';
import { GetApp as DownloadIcon } from '@material-ui/icons';

import { useEffectOnSuccess } from '../../../lib/hooks';
import { useDownloadTicket } from '../../hooks';

const { REACT_APP_SERVER_URL: SERVER_URL } = process.env;

function DownloadTicket({ id }) {
  const [getToken, result] = useDownloadTicket(id);
  const { data } = result;

  useEffectOnSuccess(result, () => {
    // Trigger download after successful token query
    const { getTicketDownloadToken: token } = data;
    window.location.href = `${SERVER_URL}ticket/download/${token}`;
  });

  return (
    <IconButton onClick={getToken}>
      <DownloadIcon />
    </IconButton>
  );
}

DownloadTicket.propTypes = {
  id: PropTypes.string.isRequired,
};

export default DownloadTicket;

import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputAdornment,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import calculateDiscount from './coupons.utils';

function CouponsCalculator({ coupons }) {
  const [amount, setAmount] = useState('');
  const [shouldRound, setShouldRound] = useState(false);
  const [selectedCoupons, setSelectedCoupons] = useState([]);
  const [finalPrice, setFinalPrice] = useState('');
  const [immutableOptions, setImmutableOptions] = useState([]);
  const [options, setOptions] = useState([]);

  // Transform raw input for select component
  useEffect(() => {
    const couponOptions = coupons.map(coupon => {
      const { discount, discountType, code } = coupon;
      return { value: { discount, discountType }, label: code };
    });
    setImmutableOptions(couponOptions);
    setOptions(couponOptions);
  }, [coupons]);

  // Handle selection changes: remove selected coupon from the options
  useEffect(() => {
    selectedCoupons.forEach(selectedCoupon => {
      const { label } = selectedCoupon;

      const selectedOption = options.find(option => option.label === label);

      if (selectedOption) {
        const index = options.indexOf(selectedOption);
        if (index > -1) {
          const mutatedOptions = [...options];
          mutatedOptions.splice(index, 1);
          setOptions(mutatedOptions);
        }
      }
    });
  }, [options, selectedCoupons]);

  useEffect(() => {
    const amountAsNumber = parseInt(amount);
    const transformedSelectedCoupons = selectedCoupons.map(coupon => {
      const { value } = coupon;
      const { discount, discountType } = value;
      return { discount, discountType };
    })
    const finalAmount = calculateDiscount(amountAsNumber, transformedSelectedCoupons, shouldRound);
    setFinalPrice(isNaN(finalAmount) ? '' : finalAmount);
  }, [amount, selectedCoupons, shouldRound, setFinalPrice]);

  return (
    <Box style={{ margin: '8px', display: 'flex', justifyContent: 'space-evenly' }}>
      <Grid>
        <Typography style={{ marginBottom: '8px' }}>Cart amount</Typography>

        <FormControl variant="outlined">
          <OutlinedInput
            value={amount}
            placeholder="Cart amount"
            onChange={e => {
              setAmount(e.target.value);
            }}
            endAdornment={<InputAdornment position="start">Ft</InputAdornment>}
          />
        </FormControl>
        <Typography style={{ margin: '8px 0px' }}>Options</Typography>
        <FormControlLabel
          control={
            <Checkbox
              checked={shouldRound}
              onChange={() => setShouldRound(!shouldRound)}
              color="primary"
            />
          }
          label="Rounding with Math.round()"
        />
        <Typography style={{ marginBottom: '8px' }}>Coupons</Typography>
        <Select
          menuPlacement="auto"
          menuPosition="fixed"
          isMulti
          name="coupons"
          options={options}
          onChange={(choice, changeEvent) => {
            console.log(changeEvent, choice);
            // Removing a single selected option --> put it back
            if (changeEvent.action === 'remove-value') {
              const currentOptions = [...options];
              currentOptions.push(changeEvent.removedValue);
              setOptions(currentOptions);
            }
            // Removing all the selected options --> put all of them back
            else if (changeEvent.action === 'clear') {
              setOptions([...immutableOptions]);
            }
            setSelectedCoupons(choice);
          }}
        />
      </Grid>
      <Grid>
        <Typography style={{ marginBottom: '8px' }}>Price with optional discounts</Typography>

        <OutlinedInput
          value={finalPrice}
          placeholder="Price with discounts"
          endAdornment={<InputAdornment position="start">Ft</InputAdornment>}
          readOnly
        />
      </Grid>
    </Box>
  );
}

export default CouponsCalculator;

import React from 'react';

import { InfoCard } from '../../../common/components';
import * as Types from '../../../../utils/propTypes';
import { formatEuro, formatForint } from '../../../../utils/formatters';
import { CurrencyOptions } from '../OrderChangeCalculator/OrderChangeCalculator';

function OrderPaymentInfoCard({ payment: { cashSubtotal, cardSubtotal }, currency }) {
  const formatSubtotal = subtotal => {
    return currency === CurrencyOptions.HUF || currency === undefined
      ? formatForint(subtotal)
      : formatEuro(subtotal);
  };
  const rows = [
    {
      label: 'Paid in cash',
      value: formatSubtotal(cashSubtotal),
    },
    {
      label: 'Paid by card',
      value: formatSubtotal(cardSubtotal),
    },
  ];
  return <InfoCard title="Payment details" rows={rows} />;
}

OrderPaymentInfoCard.propTypes = {
  payment: Types.payment.isRequired,
};

export default OrderPaymentInfoCard;

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetMergedTicketsTableData(eventId, enabled) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['copiable-merged-tickets', eventId],
        queryFn: async () => {
            const token = localStorage.getItem('token')
            const { data } = await axios.get(`${REACT_APP_SERVER_URL}event/${eventId}/tickets/copy-to-clipboard`, { headers: { Authorization: token } })
            return data;
        },
        refetchOnWindowFocus: false,
        enabled: !!enabled && !!eventId,
    });
}

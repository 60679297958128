import React, { useState } from 'react';

import { Main } from '../../../../common/components';
import { FillerSpinner } from '../../../../lib/components';
import { useGetOpenOrder } from '../../../hooks';
import { CurrencyOptions, PaymentModes } from '../../OrderChangeCalculator/OrderChangeCalculator';
import OrderProductSummaryToolbar from '../OrderProductSummaryToolbar';
import OrderProductItemsInfoCard from '../OrderProductItemsInfoCard';
import useLatestExchangeRate from '../../../../exchangeRate/useLatestExchangeRate';

function OrderProductSummary() {
  const { loading, data } = useGetOpenOrder();

  const { data: exchangeRate, isSuccess } = useLatestExchangeRate();
  const [paidAmounts, setPaidAmounts] = useState({
    cash: 0,
    card: 0,
  });
  const [terminal, setTerminal] = useState(null);
  const [paymentMode, setPaymentMode] = useState(PaymentModes.MIXED);
  const [currencyOption, setCurrencyOption] = useState(CurrencyOptions.HUF);

  if (loading) return <FillerSpinner />;

  const { getOpenOrder: order } = data;

  return (
    <Main
      toolbar={
        <OrderProductSummaryToolbar
          order={order}
          paidAmounts={paidAmounts}
          terminal={terminal}
          paymentMode={paymentMode}
          currencyOption={currencyOption}
          exchangeRate={exchangeRate?.exchangeRate}
        />
      }
      primary={
        <OrderProductItemsInfoCard
          order={order}
          paidAmounts={paidAmounts}
          setPaidAmounts={setPaidAmounts}
          terminal={terminal}
          setTerminal={setTerminal}
          paymentMode={paymentMode}
          setPaymentMode={setPaymentMode}
          currencyOption={currencyOption}
          setCurrencyOption={setCurrencyOption}
          exchangeRate={exchangeRate?.exchangeRate}
        />
      }
    />
  );
}

export default OrderProductSummary;

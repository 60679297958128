import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export const useGetV2TicketById = (v2TicketId) => {
    return useQuery({
        queryKey: ['ticket-v2-by-id'],
        queryFn: async () => {
            const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}tickets/v2/${v2TicketId}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};

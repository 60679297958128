import React, { useState } from 'react';
import { Typography, TextField, Button, Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { Select } from '@material-ui/core';
import { MenuItem } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { formatEuro, formatForint } from '../../../../../utils/formatters';
import useGetTerminals from '../../../../terminals/hooks/useGetTerminals';
import { CurrencyOptions } from '../../OrderChangeCalculator/OrderChangeCalculator';

const useStyles = makeStyles(theme => ({
  marginBottom: {
    marginBottom: theme.spacing(4),
  },
  paymentModeButton: {
    margin: '10px 5px',
  },
}));

export const PaymentModes = {
  CASH: 'CASH',
  CARD: 'CARD',
  MIXED: 'MIXED',
};

function OrderProductChangeCalculator({
  order: { /* tickets, elbCards, */ products },
  paidAmounts,
  setPaidAmounts,
  terminal,
  setTerminal,
  paymentMode,
  setPaymentMode,
  currencyOption,
  setCurrencyOption,
  exchangeRate,
}) {
  const classes = useStyles();
  const { loading, data: terminals } = useGetTerminals();
  const [paidAmount, setPaidAmount] = useState(0);
  const [paidWithCardAmount, setPaidWithCardAmount] = useState(0);
  // Change calculator
  const [cashForChange, setCashForChange] = useState(0);
  const [cardForChange, setCardForChange] = useState(0);

  const handleTerminalSelectChange = event => {
    setTerminal(event.target.value);
  };

  const handlePaymentModeChange = newPaymentMode => {
    setPaymentMode(newPaymentMode);
    setPaidAmount(0);
    setPaidWithCardAmount(0);
    setPaidAmounts({ cash: 0, card: 0 });
  };

  const tickets = products.filter(product => product.type === 'ticket');
  const elbCards = products.filter(product => product.type === 'elb-card');
  const ticketsPriceInHuf = tickets
    .map(ticket => {
      return ticket.priceInHuf;
    })
    .reduce((sum, priceInHuf) => sum + priceInHuf, 0);
  const elbCardsPriceInHuf = elbCards
    .map(elbCard => {
      return elbCard.priceInHuf;
    })
    .reduce((sum, priceInHuf) => sum + priceInHuf, 0);
  const fullPriceInHuf = ticketsPriceInHuf + elbCardsPriceInHuf;

  const ticketsPriceInEur = tickets
    .map(ticket => {
      return ticket.priceInHuf !== 0 ? 0 : ticket.priceInEur;
    })
    .reduce((sum, priceInEur) => sum + priceInEur, 0);
  const elbCardsPriceInEur = elbCards
    .map(elbCard => {
      return elbCard.priceInHuf !== 0 ? 0 : elbCard.priceInEur;
    })
    .reduce((sum, priceInEur) => sum + priceInEur, 0);
  const fullPriceInEur = ticketsPriceInEur + elbCardsPriceInEur;

  const fullPrice =
    currencyOption === CurrencyOptions.HUF
      ? fullPriceInHuf + parseInt((fullPriceInEur * exchangeRate).toFixed(0))
      : parseFloat((fullPriceInHuf / exchangeRate).toFixed(2)) + fullPriceInEur;

  const change = parseInt(cashForChange) + parseInt(cardForChange) - fullPrice;

  const changePaidAmountWithPaymentMode = (paymentType, newValue) => {
    let localPaidAmounts = {
      cash: paidAmounts.cash,
      card: paidAmounts.card,
    };
    if (paymentType === PaymentModes.CASH) {
      localPaidAmounts.cash = newValue;
      setPaidAmount(newValue);
    } else if (paymentType === PaymentModes.CARD) {
      localPaidAmounts.card = newValue;
      setPaidWithCardAmount(newValue);
    }
    setPaidAmounts(localPaidAmounts);
  };

  return (
    <>
      <Typography variant="h6" style={{ margin: '10px 0px' }}>
        Today's exchange rate: {exchangeRate || 0}
      </Typography>
      <Typography variant="h3" component="h1" style={{ margin: '10px 0px' }}>
        Payment Currency:
      </Typography>
      <Grid container>
        <Grid item>
          <Button
            variant={currencyOption === CurrencyOptions.HUF ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '10px 5px' }}
            onClick={() => setCurrencyOption(CurrencyOptions.HUF)}
          >
            HUF
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={currencyOption === CurrencyOptions.EUR ? 'contained' : 'outlined'}
            color="primary"
            style={{ margin: '10px 5px' }}
            onClick={() => setCurrencyOption(CurrencyOptions.EUR)}
          >
            EUR
          </Button>
        </Grid>
      </Grid>
      <Typography variant="h3" component="h1" style={{ margin: '10px 0px' }}>
        {`Full Price (HUF): ${
          currencyOption === CurrencyOptions.HUF
            ? formatForint(Math.round(fullPrice / 10) * 10)
            : formatEuro(fullPrice)
        }`}
      </Typography>

      <Typography variant="h3" component="h1" style={{ margin: '10px 0px' }}>
        Change calculator
      </Typography>
      <Typography variant="body1" component="p">
        This helps to handle the change. These numbers won't be saved.
      </Typography>

      <TextField
        type="number"
        value={cashForChange}
        inputProps={{ min: 0, max: 1000000 }}
        onChange={e => setCashForChange(e.target.value)}
        autoFocus
        label="Cash"
        variant="outlined"
        margin="normal"
        style={{ marginRight: '10px' }}
      />

      <TextField
        type="number"
        value={cardForChange}
        inputProps={{ min: 0, max: 1000000 }}
        onChange={e => setCardForChange(e.target.value)}
        autoFocus
        label="Card"
        variant="outlined"
        margin="normal"
        style={{ marginRight: '10px' }}
      />

      <Typography variant="h3" component="h1" className={classes.marginBottom}>
        {`Change: ${
          currencyOption === CurrencyOptions.HUF
            ? formatForint(Math.round(change / 10) * 10)
            : formatEuro(change)
        }`}
      </Typography>

      <Typography variant="h3" component="h1" style={{ margin: '10px 0px' }}>
        Payment Method
      </Typography>

      <Grid container>
        <Grid item>
          <Button
            variant={paymentMode === PaymentModes.CASH ? 'contained' : 'outlined'}
            color="primary"
            className={classes.paymentModeButton}
            onClick={() => handlePaymentModeChange(PaymentModes.CASH)}
          >
            Pay only with cash
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={paymentMode === PaymentModes.CARD ? 'contained' : 'outlined'}
            color="primary"
            className={classes.paymentModeButton}
            onClick={() => handlePaymentModeChange(PaymentModes.CARD)}
          >
            Pay only with card
          </Button>
        </Grid>
        <Grid item>
          <Button
            variant={paymentMode === PaymentModes.MIXED ? 'contained' : 'outlined'}
            color="primary"
            className={classes.paymentModeButton}
            onClick={() => handlePaymentModeChange(PaymentModes.MIXED)}
          >
            Pay mixed
          </Button>
        </Grid>
      </Grid>

      {paymentMode === PaymentModes.CASH && (
        <Typography variant="body1" component="p" style={{ color: 'red' }}>
          Pay only with cash: we save the full price (HUF) as the amount of the order.
        </Typography>
      )}

      {paymentMode === PaymentModes.CARD && (
        <Typography variant="body1" component="p" style={{ color: 'red' }}>
          Pay only with card: we save the full price (HUF) as the amount of the order. You must
          choose a terminal.
        </Typography>
      )}

      {paymentMode === PaymentModes.MIXED && (
        <>
          <Typography variant="body1" component="p" style={{ color: 'red' }}>
            You can only finalize orders where the sum of Cash subtotal and Card subtotal equals to
            the full price (HUF)!
          </Typography>
        </>
      )}

      {(paymentMode === PaymentModes.CARD || paymentMode === PaymentModes.MIXED) &&
        !loading &&
        terminals && (
          <Grid contanier style={{ margin: '10px 0px' }}>
            <Grid item>
              <FormControl>
                <InputLabel id="terminal-simple-select">Terminal</InputLabel>
                <Select
                  labelId="terminal-simple-select"
                  id="terminal-simple-select"
                  value={terminal}
                  onChange={handleTerminalSelectChange}
                  style={{ minWidth: '200px' }}
                >
                  {terminals.terminals.entries
                    .filter(terminal => terminal.account !== null)
                    .map(terminal => (
                      <MenuItem value={terminal.id}>{terminal.name}</MenuItem>
                    ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        )}

      {paymentMode === PaymentModes.MIXED && (
        <TextField
          type="number"
          value={paidAmount}
          inputProps={{ min: 0, max: 1000000 }}
          onChange={e => changePaidAmountWithPaymentMode(PaymentModes.CASH, e.target.value)}
          autoFocus
          label="Paid in cash"
          variant="outlined"
          margin="normal"
          style={{ marginRight: '10px' }}
        />
      )}

      {paymentMode === PaymentModes.MIXED && (
        <TextField
          type="number"
          value={paidWithCardAmount}
          inputProps={{ min: 0, max: 1000000 }}
          onChange={e => changePaidAmountWithPaymentMode(PaymentModes.CARD, e.target.value)}
          autoFocus
          label="Paid by card"
          variant="outlined"
          margin="normal"
        />
      )}

      <div style={{ paddingBottom: '30px' }}></div>
    </>
  );
}

export default OrderProductChangeCalculator;

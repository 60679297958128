import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import {
  FormikSwitch as FSwitch,
  FormikTextField as FTextField,
  FormikDateTimePicker as FDateTimePicker,
  FormikFormError as FFormError,
  Fields,
} from '../../../lib/components';
import { FormikBillingAccountSelect as FBillingAccountSelect } from '../../../billing/components';
import { FormikFormAutocomplete as FFormAutocomplete } from '..';

function EventFormFields({ create = false, isEditing, form }) {
  return (
    <Fields>
      <FSwitch name="isExternal" label="External" />

      <FTextField
        name="name"
        type="text"
        required
        label="Name"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FDateTimePicker
            name="from"
            disablePast
            required
            label="Start date"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>

        <Grid item xs={6}>
          <FDateTimePicker
            name="until"
            disablePast
            required
            label="End date"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>

      <FTextField
        name="location"
        type="text"
        label="Location"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FTextField
        name="ticketLimit"
        type="number"
        label="Ticket Limit"
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FBillingAccountSelect
        name="billingAccount"
        required
        variant="outlined"
        fullWidth
        margin="normal"
      />

      <FFormAutocomplete
        name="form"
        InputProps={{
          variant: 'outlined',
          fullWidth: true,
          margin: 'normal',
        }}
        disabled={isEditing === true && form !== ''}
        disableClearable={isEditing === true && form !== ''}
      />

      <FSwitch name="isFreeWithElbCard" label="Free with ELB Card" />
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FDateTimePicker
            name="freeWithElbCardFrom"
            disablePast
            label="Free with ELB Card start date"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>

        <Grid item xs={6}>
          <FDateTimePicker
            name="freeWithElbCardUntil"
            disablePast
            label="Free with ELB Card end date"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={6}>
          <FDateTimePicker
            name="canCheckingFrom"
            disablePast
            label="Can Check start date"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>

        <Grid item xs={6}>
          <FDateTimePicker
            name="canCheckingUntil"
            disablePast
            label="Can Check end date"
            variant="outlined"
            fullWidth
            margin="normal"
          />
        </Grid>
      </Grid>

      {isEditing === true && form !== '' && (
        <Typography variant="body1" component="p" style={{ color: 'red' }}>
          You can delete the form on the left side panel.
        </Typography>
      )}

      {create ? <FSwitch name="startSales" label="Start Sales" /> : null}

      <FFormError />
    </Fields>
  );
}

export default EventFormFields;

import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export default function useGetAllEventsName() {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['all-event-name'],
        queryFn: async () => {
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}get-all-events`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { useGetProducts } from '../../hooks';
import { Box, TextField, Typography } from '@material-ui/core';
import EventSummary from '../EventSummary';
import { FillerSpinner } from '../../../lib/components';
import { useGetAllEventsName } from '../../../events/hooks';

function ProductsSelect({ type, onProductSelect, setSelectedEvent }) {
  const handleSelectedEvent = event => {
    setSelectedEvent(event);
  };
  const values = {
    type,
  };
  const { isSuccess, data, isLoading, isError } = useGetProducts(values);
  const [value, setValue] = useState();
  const readableProductType = type === 'ticket' ? 'ticket' : 'ELB Card';

  const { data: eventData } = useGetAllEventsName();
  const sortedProductData =
    isSuccess &&
    data?.sort((a, b) => {
      if (
        a.eventId === null ||
        (a.eventId === undefined && b.eventId === null) ||
        b.eventId === undefined
      )
        return 0;
      if (a.eventId === null || a.eventId === undefined) return -1;
      if (b.eventId === null || b.eventId === undefined) return 1;

      return b.eventId.localeCompare(a.eventId);
    });

  return (
    <Box display={'flex'} flexDirection={'column'}>
      <Box marginBottom={'8px'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        {isLoading && (
          <Box>
            <Typography>Loading {readableProductType} products...</Typography>
            <FillerSpinner />
          </Box>
        )}
        {isError && (
          <Typography style={{ color: 'red' }}>
            {' '}
            Could not load related {readableProductType} products
          </Typography>
        )}
        {isSuccess && (
          <Autocomplete
            id="select-type"
            options={sortedProductData}
            groupBy={option => {
              const foundEvent =
                option.eventId && (eventData || []).find(event => event.id === option.eventId);
              return foundEvent !== undefined
                ? foundEvent?.name
                : type === 'ticket'
                ? 'Tickets without event'
                : '';
            }}
            getOptionLabel={option => option.name}
            value={value}
            onChange={async (event, newValue) => {
              setValue(newValue);
              return onProductSelect(newValue);
            }}
            renderInput={param => (
              <TextField
                {...param}
                margin="normal"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                style={{ width: '550px' }}
                label={type === 'ticket' ? 'Ticket Type' : 'ELB Card Type'}
              />
            )}
          />
        )}
      </Box>

      <Box marginBottom={'8px'} display={'flex'} flexDirection={'row'} justifyContent={'center'}>
        {value?.eventId && (
          <EventSummary eventId={value.eventId} setSelectedEvent={handleSelectedEvent} />
        )}
      </Box>
    </Box>
  );
}

export default ProductsSelect;

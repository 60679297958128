import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

export default function useCheckProductTicketAvailability() {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useMutation({
        mutationKey: ['check-product-ticket-availability'],
        mutationFn: async (payload) => {
            //const ELB_BACKOFFICE_ADMIN_APP_TOKEN = localStorage.getItem('token')
            const response = await axios.post(
                `${REACT_APP_SERVER_URL}webshop/v0/tickets/availability`,
                payload
            );
            return response?.data;
        },
    });
}

import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { useEffectOnSuccess } from '../../../../lib/hooks';
import { useCompleteProductOrderWithPaidAmounts } from '../../../hooks';
import { ActionButtonWithConfirmation } from '../../../../common/components';
import { PaymentModes } from '../OrderProductChangeCalculator/OrderProductChangeCalculator';
import { CurrencyOptions } from '../../OrderChangeCalculator/OrderChangeCalculator';

function FinalizeProductOrder({
  order,
  paidAmounts,
  terminal,
  paymentMode,
  currencyOption,
  exchangeRate,
}) {
  const history = useHistory();

  const [completeProductOrderWithPaidAmounts, res] = useCompleteProductOrderWithPaidAmounts({
    payment: {
      cashSubtotal: paidAmounts.cash,
      cardSubtotal: paidAmounts.card,
    },
    terminal,
  });

  useEffectOnSuccess(res, () => {
    history.push('/open-order-with-products');
  });

  const buttonProps = {
    variant: 'contained',
    color: 'primary',
    disabled: order.isEmpty,
  };

  const mapAmountFromPaymentMode = () => {
    if (paymentMode === PaymentModes.CASH) {
      return {
        payment: {
          cashSubtotal: fullPrice,
          cardSubtotal: 0,
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    } else if (paymentMode === PaymentModes.CARD) {
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: fullPrice,
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    } else if (paymentMode === PaymentModes.MIXED) {
      return {
        payment: {
          cashSubtotal: parseInt(paidAmounts.cash),
          cardSubtotal: parseInt(paidAmounts.card),
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    } else {
      // We should not be here in the else branch
      return {
        payment: {
          cashSubtotal: 0,
          cardSubtotal: 0,
        },
        terminal,
        currency: currencyOption,
        exchangeRate,
      };
    }
  };

  const [fullPrice, setFullPrice] = useState(0);
  useEffect(() => {
    const ticketsPriceInHuf = order.products
      .filter(product => product.type === 'ticket')
      .reduce((sum, { priceInHuf }) => sum + priceInHuf, 0);
    const elbCardsPriceInHuf = order.products
      .filter(product => product.type === 'elb-card')
      .reduce((sum, { priceInHuf }) => sum + priceInHuf, 0);
    const fullPriceInHuf = ticketsPriceInHuf + elbCardsPriceInHuf;

    const ticketsPriceInEur = order.products
      .filter(product => product.type === 'ticket')
      .reduce((sum, { priceInEur, priceInHuf }) => {
        const priceToUseInEur = priceInHuf !== 0 ? 0 : priceInEur;
        return sum + priceToUseInEur;
      }, 0);
    const elbCardsPriceInEur = order.products
      .filter(product => product.type === 'elb-card')
      .reduce((sum, { priceInEur, priceInHuf }) => {
        const priceToUseInEur = priceInHuf !== 0 ? 0 : priceInEur;
        return sum + priceToUseInEur;
      }, 0);
    const fullPriceInEur = ticketsPriceInEur + elbCardsPriceInEur;

    const newFullPrice =
      currencyOption === CurrencyOptions.HUF
        ? fullPriceInHuf + parseInt((fullPriceInEur * exchangeRate).toFixed(0))
        : parseFloat((fullPriceInHuf / exchangeRate).toFixed(2)) + fullPriceInEur;
    setFullPrice(newFullPrice);
  }, [order, paidAmounts]);

  console.log({ fullPrice });

  const calculateDisabledStateOfButton = () => {
    switch (paymentMode) {
      case PaymentModes.CASH: {
        return false;
      }
      case PaymentModes.CARD: {
        return false;
      }
      case PaymentModes.MIXED: {
        const amountsEquality =
          parseFloat(paidAmounts.cash) + parseFloat(paidAmounts.card) === fullPrice;
        const result = !(amountsEquality && terminal !== null);
        return result;
      }
      default:
        return false;
    }
  };

  return (
    <ActionButtonWithConfirmation
      action={() => completeProductOrderWithPaidAmounts(mapAmountFromPaymentMode())}
      result={res}
      buttonText="Finalize"
      buttonProps={buttonProps}
      successMessage="Order has been completed!"
      disabled={calculateDisabledStateOfButton()}
    />
  );
}

export default FinalizeProductOrder;

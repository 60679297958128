import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';

export default function useGetElbCardBySearchKeyword(keyword) {
  const REACT_APP_API_V2_BASE_URL = process.env.REACT_APP_API_V2_BASE_URL;
  return useQuery({
    queryKey: ['get-elb-card-by-keyword', keyword],
    queryFn: async () => {
        const response = await axios.get(
          `${REACT_APP_API_V2_BASE_URL}/checking/search?keyword=${keyword}`,
        );
        const data = await response.data;
        return data;
    },
    refetchOnWindowFocus: false,
    staleTime: 5 * 60 * 1000,
    enabled: !!keyword,
  })
}
import React, { useEffect, useState } from 'react';
import { QrReader } from 'react-qr-reader';
import { Box, Button, CircularProgress, Typography } from '@material-ui/core';
import useGetElbCardBySearchKeyword from '../../../checking/useGetElbCardBySearchKeyword.hook';
import { QrCodeResultDialog } from '../QrCodeResultDialog';

const CAMERA_TYPES = Object.freeze({
  FRONT_FACING: 'user',
  BACK_CAMERA: 'environment',
});

const validateReadContentFormat = data => {
  const isOnlineELBCardNumber = /^ELBo\d{5}x[A-Za-z0-9]{6}$/.test(data);
  const isELBCardNumber = /^(ELB)?\d{5}[A-Z]$/.test(data);
  //TODO const isTicketNumber hiányzik
  const isTicketNumber2ndGen = /^\d{4}o\dt\dx\d[a-zA-Z]{10}$/.test(data);
  const isV2TicketNumber = /^[a-zA-Z0-9]{10}$/.test(data);
  return isOnlineELBCardNumber || isELBCardNumber || isTicketNumber2ndGen || isV2TicketNumber;
};

const QrCodeReading = () => {
  const [data, setData] = useState(null);
  const [isReading, setIsReading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const {
    data: newCheckingEntryData,
    isSuccess,
    refetch,
    isError: isApiResponseError,
  } = useGetElbCardBySearchKeyword(data);

  useEffect(() => {
    if (isSuccess || isApiResponseError) {
      setIsLoading(false);
      setOpen(true);
    }
  }, [isApiResponseError, isSuccess]);

  const toggleQrReading = () => {
    setIsReading(previous => !previous);
    if (data) {
      setData(null);
    }
    if (isError) setIsError(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        padding: '8px',
      }}
    >
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          columnGap: '15px',
        }}
      >
        <Button variant="contained" color="primary" onClick={toggleQrReading}>
          {isReading ? 'STOP CAMERA' : 'START CAMERA'}
        </Button>
        <Button variant="outlined" color="primary" onClick={() => setData(null)}>
          RESET READ DATA
        </Button>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: '8px',
          marginBottom: '8px',
        }}
      >
        <div>
          <Typography variant="h5" align="center">
            QR code content
          </Typography>
          <Typography
            variant="body1"
            align="center"
            style={{
              marginTop: '8px',
            }}
          >
            {data || 'No QR code content yet'}
          </Typography>
        </div>
      </div>
      {isLoading && (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'16px'}>
          <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '8px' }}>
            Checking ELB Card...
          </Typography>
          <CircularProgress />
        </Box>
      )}
      {isSuccess && (
        <QrCodeResultDialog
          checkingData={newCheckingEntryData?.find(card => card.status === 'Active')}
          open={open}
          setOpen={setOpen}
          setData={setData}
          isError={
            isApiResponseError ||
            newCheckingEntryData.length === 0 ||
            newCheckingEntryData?.find(card => card.status === 'Active') === undefined
          }
          toggleQrReading={toggleQrReading}
          qrCodeContent={data}
        />
      )}
      {isError && !data && (
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'} mt={'16px'}>
          <Typography variant="h4" style={{ fontWeight: 'bold', color: 'red' }}>
            Read QR Code content format is not valid.
          </Typography>
        </Box>
      )}
      {isReading && (
        <QrReader
          delay={500}
          onResult={(result, error) => {
            if (!!result) {
              console.log('onResult result', result);
              const validatedText = validateReadContentFormat(result?.text);
              if (validatedText) {
                setData(result?.text);
                setIsLoading(true);
                toggleQrReading();
                refetch(result?.text);
              } else {
                setIsError(true);
              }
            }

            if (!!error) {
              if (error?.message) {
                console.log('QrReader error', error);
                console.log('QrReader error?.message', error?.message);
              }
            }
          }}
          style={{
            width: '100%',
          }}
          constraints={{
            facingMode: CAMERA_TYPES.BACK_CAMERA,
          }}
        />
      )}
    </div>
  );
};

export default QrCodeReading;

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { IconButton, Tooltip } from '@material-ui/core';
import { FileCopyOutlined as CopyIcon } from '@material-ui/icons';
import * as Types from '../../../../utils/propTypes';

import { useSnackbar } from '../../../lib/hooks';
import { useGetMergedTicketsTableData } from '../../hooks';

async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

function ExportTicketsButton({ eventId }) {
  const [shouldFetchData, setShouldFetchData] = useState(false);
  const { data, isSuccess } = useGetMergedTicketsTableData(eventId, shouldFetchData);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (isSuccess) {
      copyTextToClipboard(data.text);
      enqueueSnackbar('Copied!', 'success', {
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      setShouldFetchData(false);
    }
  }, [isSuccess]);

  return (
    <Tooltip title="Copy to clipboard">
      <IconButton onClick={() => setShouldFetchData(true)}>
        <CopyIcon />
      </IconButton>
    </Tooltip>
  );
}

ExportTicketsButton.propTypes = {
  collectionName: PropTypes.string,
  options: Types.queryOptions,
};

export default ExportTicketsButton;

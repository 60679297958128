import React from 'react';
import {
  Dashboard as DashboardIcon,
  People as PeopleIcon,
  Face as FaceIcon,
  AccountBalance as AccountBalanceIcon,
  SwapHoriz as TransactionsIcon,
  CardMembership as ELBCardIcon,
  MonetizationOn as OrdersIcon,
  LocalBar as EventsIcon,
  ConfirmationNumber as TicketsIcon,
  ContactSupport as AuditLogsIcon,
  Settings as SettingsIcon,
  LocalAtm as BillingAccountsIcon,
  CreditCard as TerminalsIcon,
  AccountBox as OnlineELBCardsIcon,
  ImageSearch as CheckELBCardIcon,
  // AlarmOn as CardRead12Hours,
  // SupervisedUserCircle as ExternalCardRead,
  CropFree as QrCodeReadingIcon,
  PaymentSharp,
  LocalOffer as CouponsPageIcon,
  Comment,
  BarChart,
  YoutubeSearchedFor
} from '@material-ui/icons';

import { findLast, kebabCase } from 'lodash';
import memoize from 'memoize-one';

import { Link } from '../modules/lib/components';
import { Main, Minimal } from '../modules/common/layouts';
import { Users, User } from '../modules/users/components';
import { Accounts, Account } from '../modules/accounts/components';
import { Transactions } from '../modules/transactions/components';
import { Dashboard, NotFound } from '../modules/common/components';
import { Activation, PasswordResetRequest, PasswordReset, Login } from '../modules/auth/components';
import { Customers, Customer } from '../modules/customers/components';
import { ELBCardTypes, ELBCardType } from '../modules/elb-card-types/components';
import { ELBCard, ELBCards } from '../modules/elb-cards/components';
import { CheckElbCard } from '../modules/check-elb-card/components';
//import { ElbCardEntry } from '../modules/elb-card-entry/components';
import { ElbCardReading } from '../modules/elb-card-reading/components';
//import { ExternalCardReading } from '../modules/external-card-reading/components';
import { Orders, Order, OpenOrder, OrderSummary } from '../modules/orders/components';
import { AuditLogs, AuditLog } from '../modules/audit/components';
import { Events, Event, TicketType, EventsMobile } from '../modules/events/components';
import { Ticket, Tickets } from '../modules/tickets/components';
import { Settings } from '../modules/settings/components';
import { BillingAccounts, BillingAccount } from '../modules/billing/components';
import Terminals from '../modules/terminals/components/Terminals/Terminals';
import Terminal from '../modules/terminals/components/Terminal/Terminal';
import CustomersWithProfilePictures from '../modules/online-elb-cards/components/CustomersWithProfilePictures/CustomersWithProfilePictures';
import { Changelog } from '../modules/changelog/components';
import Privileges from './access-control/privileges.json';
import { EventChecking, EventCheckingStatistics } from '../modules/events/components/EventChecking';
import { Product, Products } from '../modules/products/components';
import { CreateProductPage } from '../modules/products/pages';
import { OpenOrderWithProducts } from '../modules/orders/components/CreateProductOrder';
import { QrCodeReading } from '../modules/qr-code-reading/components/QrCodeReading';
import globalApplicationConfiguration from '../config/config';
import Payment from '../modules/payments/components/Payment/Payment';
import { PaymentStatistics } from '../modules/payments/components';
import OrderProductSummary from '../modules/orders/components/CreateProductOrder/OrderProductSummary';
import CouponsPage from '../modules/coupons/Coupons.page';
import NotesPage from '../modules/notes/Notes.page';
import V2TicketsPage from '../modules/tickets-v2/v2Tickets.page';
import CheckingPage from '../modules/checking/Checking.page';
import ExchangeRate from '../modules/exchangeRate/ExchangeRate.page';
import CheckingHistory from '../modules/checking-history/CheckingHistory.page';
import V2TicketPage from '../modules/tickets-v2/v2Ticket.page';

const documentRegex = /(id=)([a-zA-Z0-9]*)&(.*)/;
export function matchDocumentLabel(urlSegment) {
  const match = urlSegment.match(documentRegex);
  if (match) return match[3];
  return null;
}

const SegmentTypes = {
  COLLECTION: 'collectionName',
  DOCUMENT: 'document',
};

function createCollectionPathSegment(collectionName) {
  return `/${collectionName}`;
}
function createCollectionUrlSegment(collectionName) {
  return `/${collectionName}`;
}

/** ('fullName', 'id') -> '/id=:id&fullName=:fullName' */
function createDocumentPathSegment(documentLabelName, idName = 'id') {
  return `/id=:${idName}&:${documentLabelName}`;
}
/** ('5d6934418ac4cc4fd4754f7b', 'Cliff Booth') -> '/id=5d6934418ac4cc4fd4754f7b&Cliff Booth' */
function createDocumentUrlSegment(id, documentLabel) {
  return `/id=${id}&${encodeURIComponent(documentLabel)}`;
}

function createPathSegment(segment) {
  const { type } = segment;
  if (type === SegmentTypes.COLLECTION) {
    return createCollectionPathSegment(segment.name);
  }
  if (type === SegmentTypes.DOCUMENT) {
    return createDocumentPathSegment(segment.labelName, segment.idName);
  }
  return null;
}
function createUrlSegment(segment) {
  const { type } = segment;
  if (type === SegmentTypes.COLLECTION) {
    return createCollectionUrlSegment(segment.name);
  }
  if (type === SegmentTypes.DOCUMENT) {
    return createDocumentUrlSegment(segment.id, segment.label);
  }
  return null;
}

function createBasicDocumentPath(collectionName, documentLabelName, idName) {
  const cps = createCollectionPathSegment(collectionName);
  const eps = createDocumentPathSegment(documentLabelName, idName);
  return `${cps}${eps}`;
}
function createBasicDocumentUrl(collectionName, id, documentLabel) {
  const cus = createCollectionUrlSegment(collectionName);
  const eus = createDocumentUrlSegment(id, documentLabel);
  return `${cus}${eus}`;
}

function createPath(segments) {
  return segments.map(s => createPathSegment(s)).join('');
}
function createUrl(segments) {
  return segments.map(s => createUrlSegment(s)).join('');
}

function createLinkFromUrl(url, linkText = 'Link', props = {}) {
  return React.createElement(Link, { ...props, to: url }, linkText);
}
function createBasicDocumentLink(
  collectionName,
  id,
  documentLabel,
  linkText = documentLabel,
  props = {},
) {
  return createLinkFromUrl(
    createBasicDocumentUrl(collectionName, id, documentLabel),
    linkText,
    props,
  );
}
function createLink(
  segments,
  linkText = findLast(segments, s => s.type === SegmentTypes.DOCUMENT).label,
  props = {},
) {
  return createLinkFromUrl(createUrl(segments), linkText, props);
}


function createProductDocumentPathSegment(id = 'id') {
  return `/products/id=:${id}`
}

function createProductDocumentUrlSegment(id) {
  return `/products/id=${id}`
}

function createBasicProductDocumentLink(id, linkText = 'product', props = {}) {
  return createLinkFromUrl(
    createProductDocumentUrlSegment(id),
    linkText,
    props
  )
}

const segments = {
  ticketType: memoize((id, name, eventId, eventName) => [
    {
      type: SegmentTypes.COLLECTION,
      name: 'events',
    },
    {
      type: SegmentTypes.DOCUMENT,
      labelName: 'eventName',
      idName: 'eventId',
      label: eventName,
      id: eventId,
    },
    {
      type: SegmentTypes.DOCUMENT,
      labelName: 'ticketTypeName',
      idName: 'ticketTypeId',
      label: name,
      id,
    },
  ]),
};

const routes = {
  dashboard: {
    path: '/dashboard',
    layout: Main,
    component: Dashboard,
    label: 'Dashboard',
    exact: true,
    isProtected: true,
    icon: DashboardIcon,
    isInSidebar: true,
  },

  orders: {
    path: '/orders',
    layout: Main,
    component: Orders,
    label: 'Orders',
    isInSidebar: true,
    icon: OrdersIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_OWN_ORDER,
  },
  order: {
    path: createBasicDocumentPath('orders', 'prettyId'),
    layout: Main,
    component: Order,
    createUrl: (id, prettyId) => createBasicDocumentUrl('orders', id, prettyId),
    createLink: (id, prettyId, linkText, props) =>
      createBasicDocumentLink('orders', id, prettyId, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_OWN_ORDER,
  },
  openOrder: {
    path: '/order-edit',
    layout: Main,
    component: OpenOrder,
    label: 'New Order',
    isProtected: true,
    exact: true,
    privilege: Privileges.CREATE_ORDER,
  },
  openOrderWithProducts: {
    path: '/open-order-with-products',
    layout: Main,
    component: OpenOrderWithProducts,
    label: 'New Product Order',
    isProtected: true,
    exact: true,
    privilege: Privileges.CREATE_ORDER,
  },
  orderSummary: {
    path: '/order-summary',
    layout: Main,
    component: OrderSummary,
    label: 'Order Summary',
    isProtected: true,
    exact: true,
    privilege: Privileges.CREATE_ORDER,
  },
  orderProductSummary: {
    path: '/order-product-summary',
    layout: Main,
    component: OrderProductSummary,
    label: 'Order Product Summary',
    isProtected: true,
    exact: true,
    privilege: Privileges.CREATE_ORDER,
  },

  users: {
    path: '/users',
    layout: Main,
    component: Users,
    label: 'Users',
    exact: true,
    isProtected: true,
    isInSidebar: true,
    icon: PeopleIcon,
    privilege: Privileges.READ_ANY_USER_BASIC_DATA,
  },
  user: {
    path: createBasicDocumentPath('users', 'fullName'),
    layout: Main,
    component: User,
    createUrl: ({ id, fullName, email }) => createBasicDocumentUrl('users', id, fullName || email),
    createLink: ({ id, fullName, email }, linkText, props) =>
      createBasicDocumentLink('users', id, fullName || email, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_USER_BASIC_DATA,
  },

  accounts: {
    path: '/accounts',
    layout: Main,
    component: Accounts,
    label: 'Accounts',
    isInSidebar: true,
    icon: AccountBalanceIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ACCOUNT,
  },
  account: {
    path: createBasicDocumentPath('accounts', 'name'),
    layout: Main,
    component: Account,
    createUrl: (id, name) => createBasicDocumentUrl('accounts', id, name),
    createLink: (id, name, linkText, props) =>
      createBasicDocumentLink('accounts', id, name, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ACCOUNT,
  },

  transactions: {
    path: '/transactions',
    layout: Main,
    component: Transactions,
    label: 'Transactions',
    isInSidebar: true,
    icon: TransactionsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_RELATED_TRANSACTION,
  },

  auditLogs: {
    path: '/audit-logs',
    layout: Main,
    component: AuditLogs,
    label: 'Audit Logs',
    isInSidebar: true,
    icon: AuditLogsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_OWN_AUDIT_LOG,
  },
  auditLog: {
    path: createBasicDocumentPath('audit-logs', 'prettyId'),
    layout: Main,
    component: AuditLog,
    createUrl: (id, prettyId) => createBasicDocumentUrl('audit-logs', id, prettyId),
    createLink: (id, prettyId, linkText, props) =>
      createBasicDocumentLink('audit-logs', id, prettyId, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_OWN_AUDIT_LOG,
  },

  // statistics: {
  //   path: '/statistics',
  //   layout: Main,
  //   component: Statistics,
  //   label: 'Statisctics',
  //   exact: true,
  //   isProtected: true,
  //   isInSidebar: true,
  //   icon: StatIcon,
  //   privilege: Privileges.READ_ANY_USER_BASIC_DATA,
  // },

  customers: {
    path: '/customers',
    layout: Main,
    component: Customers,
    label: 'Customers',
    exact: true,
    isProtected: true,
    isInSidebar: true,
    icon: FaceIcon,
    privilege: Privileges.READ_ANY_CUSTOMER,
  },
  customer: {
    path: createBasicDocumentPath('customers', 'fullName'),
    layout: Main,
    component: Customer,
    createUrl: (id, fullName) => createBasicDocumentUrl('customers', id, fullName),
    createLink: (id, fullName, linkText, props) =>
      createBasicDocumentLink('customers', id, fullName, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_CUSTOMER,
  },

  elbCardTypes: {
    path: '/elb-card-types',
    layout: Main,
    component: ELBCardTypes,
    label: 'ELB Card Types',
    isInSidebar: true,
    icon: ELBCardIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD_TYPE,
  },
  elbCardType: {
    path: createBasicDocumentPath('elb-card-types', 'name'),
    layout: Main,
    component: ELBCardType,
    createUrl: (id, name) => createBasicDocumentUrl('elb-card-types', id, name),
    createLink: (id, name, linkText, props) =>
      createBasicDocumentLink('elb-card-types', id, name, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD_TYPE,
  },

  elbCards: {
    path: '/elb-cards',
    layout: Main,
    component: ELBCards,
    label: 'ELB Cards',
    isInSidebar: true,
    icon: ELBCardIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD,
  },
  elbCard: {
    path: createBasicDocumentPath('elb-cards', 'number'),
    layout: Main,
    component: ELBCard,
    createUrl: (id, number) => createBasicDocumentUrl('elb-cards', id, number),
    createLink: (id, number, linkText, props) =>
      createBasicDocumentLink('elb-cards', id, number, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD,
  },
  checking: {
    path: '/checking',
    layout: Main,
    component: CheckingPage,
    label: 'Checking',
    isInSidebar: true,
    icon: CheckELBCardIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD
  },
  checkingHistory: {
    path: '/checking-history',
    layout: Main,
    component: CheckingHistory,
    label: 'Checking History',
    isInSidebar: true,
    icon: YoutubeSearchedFor,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD
  },
  checkElbCard: {
    path: '/check-elb-card',
    layout: Main,
    component: CheckElbCard,
    label: 'Check ELB Card',
    isInSidebar: true,
    icon: OnlineELBCardsIcon,
    //icon: CheckELBCard,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD,
  },
  cardReadingLast12Hours: {
    path: '/card-reading-last-12-hours',
    layout: Main,
    component: ElbCardReading,
    label: 'Card Reading Last 12 Hours',
    isInSidebar: true,
    icon: OnlineELBCardsIcon,
    //icon: CardRead12Hours,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD,
  },
  externalCardReading: {
    path: '/external-card-reading',
    layout: Main,
    component: ElbCardReading,
    label: 'External Card Reading',
    isInSidebar: true,
    icon: OnlineELBCardsIcon,
    //icon: ExternalCardRead,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_CARD_READING,
  },

  events: {
    path: '/events',
    layout: Main,
    component: Events,
    label: 'Events',
    isInSidebar: true,
    icon: EventsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_EVENT,
  },
  event: {
    path: createBasicDocumentPath('events', 'name'),
    layout: Main,
    component: Event,
    createUrl: (id, name) => createBasicDocumentUrl('events', id, name),
    createLink: (id, name, linkText, props) =>
      createBasicDocumentLink('events', id, name, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_EVENT,
  },
  eventChecking: {
    path: '/event-checking',
    layout: Main,
    component: EventChecking,
    label: 'Event Checking',
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD,
  },
  eventCheckingStatistics: {
    path: '/event-checking-stats',
    layout: Main,
    component: EventCheckingStatistics,
    label: 'Event checking statistics',
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_EVENT,
  },
  eventsMobile: {
    path: '/events-mobile',
    layout: Main,
    component: EventsMobile,
    label: 'Events Mobile',
    isInSidebar: true,
    icon: EventsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_EVENT,
  },

  exchangeRate: {
    path: '/exchange-rate',
    layout: Main,
    component: ExchangeRate,
    label: 'Exchange Rate',
    isInSidebar: true,
    icon: BarChart,
    exact: true,
    isProtected: true,
    //TODO: fix privilege
    privilege: Privileges.READ_ANY_EVENT
  },

  tickets: {
    path: '/tickets',
    layout: Main,
    component: Tickets,
    label: 'Tickets',
    isInSidebar: true,
    icon: TicketsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_TICKET,
  },
  ticket: {
    path: createBasicDocumentPath('tickets', 'ticketNumber'),
    layout: Main,
    component: Ticket,
    createUrl: (id, ticketNumber) => createBasicDocumentUrl('tickets', id, ticketNumber),
    createLink: (id, ticketNumber, linkText, props) =>
      createBasicDocumentLink('tickets', id, ticketNumber, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_TICKET,
  },

  ticketType: {
    path: createPath(segments.ticketType()),
    layout: Main,
    component: TicketType,
    createUrl: (id, name, eventId, eventName) =>
      createUrl(segments.ticketType(id, name, eventId, eventName)),
    createLink: (id, name, eventId, eventName, linkText, props) =>
      createLink(segments.ticketType(id, name, eventId, eventName), linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_EVENT,
  },

  ticketsV2: {
    path: '/tickets-v2',
    layout: Main,
    component: V2TicketsPage,
    label: 'Tickets (v2)',
    isInSidebar: true,
    icon: TicketsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_TICKET,
  },

  ticketV2: {
    path: createBasicDocumentPath('tickets-v2', 'ticketNumber'),
    layout: Main,
    component: V2TicketPage,
    createUrl: (id, ticketNumber) => createBasicDocumentUrl('tickets-v2', id, ticketNumber),
    createLink: (id, ticketNumber, linkText, props) =>
      createBasicDocumentLink('tickets-v2', id, ticketNumber, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_TICKET,
  },

  products: {
    path: '/products',
    layout: Main,
    component: Products,
    label: 'Products',
    isInSidebar: globalApplicationConfiguration.featureFlags.products.displayProductsMenu,
    icon: TicketsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_PRODUCT,
  },

  product: {
    path: createProductDocumentPathSegment('id'),
    layout: Main,
    component: Product,
    createUrl: (id) => createProductDocumentUrlSegment(id),
    createLink: (id) => createBasicProductDocumentLink(id),
    //{ return React.createElement(Link, { ...props, to: `/product/id=${id}` }/* , linkText */); },
    //createBasicDocumentLink('products', id, linkText, props),
    label: 'Product',
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_PRODUCT
  },

  createProduct: {
    path: '/create-product',
    layout: Main,
    component: CreateProductPage,
    label: 'Create new product',
    isProtected: true,
    exact: true,
    privilege: Privileges.CREATE_PRODUCT,
  },

  payments: {
    path: '/payments',
    layout: Main,
    component: Payment,
    label: 'Payments',
    isInSidebar: true,
    icon: PaymentSharp,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_PAYMENT,
  },

  paymentStatistics: {
    path: '/payment-statistics',
    layout: Main,
    component: PaymentStatistics,
    label: 'Payment Statistics',
    isInSidebar: true,
    icon: PaymentSharp,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_PAYMENT,
  },

  coupons: {
    path: '/coupons',
    layout: Main,
    component: CouponsPage,
    label: 'Coupons',
    isInSidebar: true,
    icon: CouponsPageIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_PRODUCT,
  },

  //TODO: Change privilege
  notes: {
    path: '/notes',
    layout: Main,
    component: NotesPage,
    label: 'Notes',
    isInSidebar: true,
    icon: Comment,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ORDER
  },

  settings: {
    path: '/settings',
    layout: Main,
    component: Settings,
    label: 'Settings',
    isInSidebar: true,
    icon: SettingsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.HANDLE_SETTINGS,
  },

  billingAccounts: {
    path: '/billing-accounts',
    layout: Main,
    component: BillingAccounts,
    label: 'Billing Accounts',
    isInSidebar: true,
    icon: BillingAccountsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_BILLING_ACCOUNT,
  },
  billingAccount: {
    path: createBasicDocumentPath('billing-accounts', 'name'),
    layout: Main,
    component: BillingAccount,
    createUrl: (id, name) => createBasicDocumentUrl('billing-accounts', id, name),
    createLink: (id, name, linkText, props) =>
      createBasicDocumentLink('billing-accounts', id, name, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.HANDLE_BILLING_ACCOUNTS,
  },

  terminals: {
    path: '/terminals',
    layout: Main,
    component: Terminals,
    label: 'Terminals',
    isInSidebar: true,
    icon: TerminalsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_TERMINAL,
  },
  terminal: {
    path: createBasicDocumentPath('terminals', 'name'),
    layout: Main,
    component: Terminal,
    createUrl: (id, name) => createBasicDocumentUrl('terminals', id, name),
    createLink: (id, name, linkText, props) =>
      createBasicDocumentLink('terminals', id, name, linkText, props),
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_TERMINAL,
  },

  onlineELBCards: {
    path: '/online-elb-cards',
    layout: Main,
    component: CustomersWithProfilePictures,
    label: 'Online ELB cards',
    isInSidebar: true,
    icon: OnlineELBCardsIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_ELB_CARD,
  },

  changelog: {
    path: '/changelog',
    layout: Main,
    component: Changelog,
    label: 'CHANGELOG',
    exact: true,
    isProtected: true,
    privilige: Privileges.READ_ANY_CHANGELOG,
  },

  qrCode: {
    path: '/qr-code',
    layout: Main,
    component: QrCodeReading,
    label: 'QR code',
    isInSidebar: globalApplicationConfiguration.featureFlags.qrCodeReading.displayQrCodeReadingMenu,
    icon: QrCodeReadingIcon,
    exact: true,
    isProtected: true,
    privilege: Privileges.READ_ANY_EVENT,
  },

  /** 404 for logged in users */
  notFoundA: {
    path: '/404a',
    layout: Main,
    component: NotFound,
    exact: true,
    isProtected: true,
  },

  /** Unprotected Routes */

  activation: {
    path: '/activation/:activationToken',
    layout: Minimal,
    component: Activation,
    exact: true,
  },

  requestPasswordReset: {
    path: '/request-password-reset',
    layout: Minimal,
    component: PasswordResetRequest,
    exact: true,
  },
  passwordReset: {
    path: '/password-reset/:resetPasswordToken',
    layout: Minimal,
    component: PasswordReset,
    exact: true,
  },

  login: {
    path: '/login',
    layout: Minimal,
    component: Login,
    exact: true,
  },

  /** Default 404 */
  notFoundB: {
    path: '/404b',
    layout: Minimal,
    component: NotFound,
    exact: true,
  },
};

export const routeItems = Object.entries(routes).map(([key, route]) => ({
  key: kebabCase(key),
  ...route,
}));

export const findRouteByKey = memoize(key => routeItems.find(r => r.key === key));

export default routes;

import React, { useState } from 'react';
import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle, Pagination } from '@material-ui/lab';
import { LimitedBackdrop } from './EventsInNextWeek';
import { format } from 'date-fns';

const useGetPurchasedElbCards = page => {
  const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
  return useQuery({
    queryKey: ['purchased-elb-card', page],
    queryFn: async () => {
      const response = await axios.get(
        `${REACT_APP_SERVER_URL}dashboard/online-purchased-elb-cards?page=${page}`,
      );
      const data = await response.data;
      return data;
    },
  });
};

const PurchasedElbCards = () => {
  const [page, setPage] = useState(1);

  const { data, isSuccess, isError, isLoading, refetch } = useGetPurchasedElbCards(page);
  console.log({ data });
  const { elbCards, numberOfElbCards, page: resultPage } = data || {};
  return (
    <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box
        style={{
          border: '1px solid black',
          width: '230px',
          height: '115px',
          margin: '8px',
          padding: '8px',
          backgroundColor: 'white',
          borderRadius: '5px',
        }}
      >
        <Typography style={{ fontWeight: '400', fontSize: '18px', marginBottom: '24px' }}>
          Online Purchased ELB Cards
        </Typography>
        <Typography style={{ fontWeight: 'bold', fontSize: '60px' }}>
          {isSuccess && numberOfElbCards}
        </Typography>
      </Box>
      <Box>
        <Box style={{ display: 'flex', alignItems: 'center', margin: '8px' }}>
          <Typography variant="body1">Online Purchased ELB Cards</Typography>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: '4px' }}
            onClick={refetch}
          >
            Refresh
          </Button>
        </Box>
        <TableContainer
          style={{
            border: '1px solid black',
            backgroundColor: 'white',
            borderRadius: '5px',
            width: '70vw',
            margin: '8px',
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Customer Email</TableCell>
                <TableCell>Customer Name</TableCell>
                <TableCell>ELB Card Type</TableCell>
                <TableCell>Price</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Card Number</TableCell>
                <TableCell>Online Card Number</TableCell>
                <TableCell>Expiration Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isError && (
                <TableRow>
                  <TableCell colSpan={8}>
                    <Alert severity="error">
                      <AlertTitle>ERROR</AlertTitle>
                      <Typography>Something went wrong</Typography>
                    </Alert>
                  </TableCell>
                </TableRow>
              )}
              {isLoading && (
                <TableRow
                  style={{
                    position: 'relative',
                    height: '150px',
                  }}
                >
                  <TableCell colSpan={8}>
                    <LimitedBackdrop open={isLoading}>
                      <CircularProgress color="primary" />
                    </LimitedBackdrop>
                  </TableCell>
                </TableRow>
              )}
              {isSuccess &&
                (elbCards.length > 0 ? (
                  elbCards.map(elbCard => {
                    const {
                      _id,
                      customerEmail,
                      customerFullName,
                      typeName,
                      price,
                      status,
                      cardNumber,
                      onlineCardNumber,
                      expirationDate,
                    } = elbCard;
                    return (
                      <TableRow key={_id}>
                        <TableCell>{customerEmail}</TableCell>
                        <TableCell>{customerFullName}</TableCell>
                        <TableCell>{typeName}</TableCell>
                        <TableCell>{price}</TableCell>
                        <TableCell>{status}</TableCell>
                        <TableCell>{cardNumber}</TableCell>
                        <TableCell>{onlineCardNumber}</TableCell>
                        <TableCell>
                          {expirationDate
                            ? format(new Date(expirationDate), 'yyyy-MM-dd hh:mm')
                            : 'No expiration date'}
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <TableRow>
                    <TableCell colSpan={8}>
                      <Alert severity="warning">
                        <AlertTitle>WARNING</AlertTitle>
                        <Typography>
                          No ELB Cards were purchased online in the last week.
                        </Typography>
                      </Alert>
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
          <Pagination
            style={{ margin: '8px' }}
            color="primary"
            count={Math.ceil(numberOfElbCards / 5)}
            page={resultPage}
            onChange={(_, newPage) => setPage(newPage)}
          />
        </TableContainer>
      </Box>
    </Box>
  );
};

export default PurchasedElbCards;

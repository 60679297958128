import React, { useState } from 'react';
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { LimitedBackdrop } from '../payments/components/Payment/Payment';
import { format } from 'date-fns';
import { useGetMergedOrderTickets } from './useGetMergedOrderTickets.hook';
import { useCheckPrivilege } from '../lib/hooks';
import Privileges from '../../utils/access-control/privileges.json';
import routes from '../../utils/routes';
import { DownloadTicket } from '../tickets/components';
import DownloadV2Ticket from './DownloadV2Ticket';
import { ExportTicketsButton } from '../common/components';
// import { DownloadTicket } from '../tickets/components';

function getHumanReadableTicketStatus(status) {
  switch (status) {
    case 'FinalizedByOnlinePayment':
      return 'Active';
    case 'ReservedForOnlinePayment':
      return 'Reserved';
    default:
      return status;
  }
}

function MergedTicketsTable({ orderId, eventId }) {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (_, newPage) => {
    setPage(newPage);
    console.log({ newPage });
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const { data, isSuccess, isLoading, isError } = useGetMergedOrderTickets({
    orderId,
    eventId,
    page,
    rowsPerPage,
  });
  const downloadAccess = useCheckPrivilege(Privileges.DOWNLOAD_TICKET);
  const colspanNumber = downloadAccess ? 12 : 11;
  return (
    <TableContainer>
      {eventId && <ExportTicketsButton eventId={eventId} />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Number</TableCell>
            <TableCell>Event</TableCell>
            <TableCell>Ticket Type</TableCell>
            <TableCell>Purchased with ELB Card</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Customer</TableCell>
            <TableCell>Customer Email</TableCell>
            <TableCell>Employee</TableCell>
            <TableCell>Sold at</TableCell>
            <TableCell>Status</TableCell>
            {downloadAccess && <TableCell>Download</TableCell>}
            <TableCell>Generation</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {isError && (
            <TableRow>
              <TableCell colSpan={colspanNumber}>
                <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  <Typography>Something went wrong!</Typography>
                </Alert>
              </TableCell>
            </TableRow>
          )}
          {isLoading && (
            <TableRow
              style={{
                position: 'relative',
                height: '110px',
              }}
            >
              <TableCell colSpan={colspanNumber}>
                <LimitedBackdrop open={isLoading} style={{ padding: '8px' }}>
                  <CircularProgress color="primary" />
                </LimitedBackdrop>
              </TableCell>
            </TableRow>
          )}
          {isSuccess &&
            (data.tickets.length > 0 ? (
              data.tickets.map(ticket => {
                const {
                  _id,
                  event,
                  eventId,
                  eventName,
                  eventTicketType,
                  customerName,
                  customerEmail,
                  generation,
                  status,
                  ticketNumber,
                  createdAt,
                  price,
                  withElbCard,
                  soldBy,
                } = ticket;
                const humanReadableStatus = getHumanReadableTicketStatus(status);
                const soldByFullNameComponent = soldBy ? routes.user.createLink(soldBy) : '';
                const eventNameLinkComponent = routes.event.createLink(eventId, eventName);

                return (
                  <TableRow key={_id}>
                    <TableCell>{ticketNumber}</TableCell>
                    <TableCell>{eventNameLinkComponent}</TableCell>
                    <TableCell>{eventTicketType}</TableCell>
                    <TableCell>{withElbCard ? 'Yes' : 'No'}</TableCell>
                    <TableCell>{price}</TableCell>
                    <TableCell>{customerName}</TableCell>
                    <TableCell>{customerEmail}</TableCell>
                    <TableCell>{soldByFullNameComponent}</TableCell>
                    <TableCell>{format(new Date(createdAt), 'yyyy-mm-dd HH:mm')}</TableCell>
                    <TableCell>{humanReadableStatus}</TableCell>
                    {/* {downloadAccess && ( */}
                    <TableCell>
                      {generation === 1 ? (
                        <DownloadTicket id={_id} />
                      ) : (
                        <DownloadV2Ticket id={_id} />
                      )}
                    </TableCell>
                    {/* )} */}
                    <TableCell>{generation}</TableCell>
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell colSpan={colspanNumber}>
                  <Alert severity="warning">
                    <AlertTitle>Tickets</AlertTitle>
                    <Typography style={{ fontSize: '16px' }}>
                      There is no ticket to display!
                    </Typography>
                  </Alert>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      {isSuccess && data?.tickets.length > 0 && data?.ticketsNumber && (
        <TablePagination
          component="div"
          count={data?.ticketsNumber}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[3, 5, 10, 15]}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
}

export default MergedTicketsTable;

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { formatDate } from '../../../../utils/formatters';

const QrCodeResultDialog = ({
  checkingData,
  open,
  setOpen,
  setData,
  isError,
  toggleQrReading,
  qrCodeContent,
}) => {
  console.log({ checkingData });
  const data = checkingData || {};
  const rows = isError
    ? []
    : [
        // {
        //   label: 'Card Number',
        //   value:
        //     data.cardNumber !== 'ELB' || !data.cardNumber ? data.cardNumber : data.onlineCardNumber,
        // },
        {
          label: 'Status',
          value: data.status,
        },
        {
          label: 'Picture',
          value: data.customer[0]?.profilePicture?.url ? (
            <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              <Box style={{ display: 'flex', justifyContent: 'center', margin: 8, width: '100%' }}>
                <img src={data.customer[0]?.profilePicture?.url} alt="" />
              </Box>
            </Box>
          ) : (
            <Typography variant="h4" style={{ fontWeight: 'bold' }}>
              No picture
            </Typography>
          ),
        },
        {
          label: 'Customer',
          value: data.customer[0].fullName,
        },
        {
          label: 'Type',
          value:
            data.type[0].name === 'UNUSED_Product_related_type'
              ? data.elbCardProductSnapshot[0].name
              : data.type[0].name,
        },
        {
          label: 'Expiration Date',
          value: data.expirationDate ? formatDate(data.expirationDate) : null,
        },
      ];

  return (
    <Dialog open={open} style={{marginTop: '16px'}}>
      <DialogContent>
        {isError ? (
          <Typography variant="h6" style={{ color: 'red', fontWeight: 'normal' }}>
            Could not find active ELB Card for received input: <strong>{qrCodeContent}</strong>
          </Typography>
        ) : (
          <Box>
            <Typography variant="h4" component="h2" gutterBottom style={{ fontWeight: 'normal' }}>
              {`Input: ${qrCodeContent}`}
            </Typography>
            <Typography variant="h4" component="h2" gutterBottom>
              {`ELB Card: ${data.cardNumber === 'ELB' ? data.onlineCardNumber : data.cardNumber} `}
            </Typography>
            <Box
              style={{
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              {rows.map(({ value }, index) => (
                <Grid container key={index}>
                  <Grid item xs={12}>
                    <Typography variant="h4" component={'div'} align="center">
                      {value}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
            </Box>
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setOpen(false);
            //setData(null);
            toggleQrReading();
          }}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QrCodeResultDialog;

function calculateDiscount(amount, selectedCoupons, enableRounding) {
    //console.log('calculateDiscount inputs', { amount, selectedCoupons, enableRounding });

    // Sort the selected coupons: move the FIXED_AMOUNT coupons to the start of the array
    selectedCoupons.sort((couponA, couponB) => couponA.discountType.localeCompare(couponB.discountType));
    //console.log('calculateDiscount sortedSelectedCoupons', sortedSelectedCoupons);

    // Handle case where no coupons are selected
    if (selectedCoupons.length === 0) {
        return amount;
    }

    let discountedPrice = amount;

    selectedCoupons.forEach(selectedCoupon => {
        const { discount, discountType } = selectedCoupon;

        if (discountType === 'PERCENTAGE') {
            discountedPrice = discountedPrice * (1 - discount / 100);
        } else if (discountType === 'FIXED_AMOUNT') {
            discountedPrice = discountedPrice - discount;
        }
    });

    // Optional rounding
    discountedPrice = enableRounding ? Math.round(discountedPrice) : discountedPrice;

    //console.log({ amount, discountedPrice });

    return discountedPrice;
};

module.exports = calculateDiscount;
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';


export default function useGetEventTicketTypeLength(eventId) {
    const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
    return useQuery({
        queryKey: ['event-ticket-types-length', eventId],
        queryFn: async () => {
            const response = await axios.get(
                `${REACT_APP_SERVER_URL}event-ticket-types-length/${eventId}`,
            );
            const data = await response.data;
            return data;
        },
        refetchOnWindowFocus: false,
    });
};

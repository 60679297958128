import React from 'react';
import { Card, CardContent, Typography, Grid } from '@material-ui/core';

import * as Types from '../../../../utils/propTypes';

import {
  SubmitButton,
  FormikAsyncWithSnackbar as Formik,
  FormikForm as FForm,
  FormikTextField as FTextField,
} from '../../../lib/components';

import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { useSnackbar } from '../../../lib/hooks';
import { globalOnlinePaymentsSettings } from '../../../../utils/validationSchemas';

function GlobalOnlinePaymentsSettings({ settings }) {

  const { enqueueSnackbar } = useSnackbar();

  const { mutate, isLoading } = useMutation({
    mutationFn: async (data) => {
      const REACT_APP_SERVER_URL = process.env.REACT_APP_SERVER_URL;
      const response = await axios.post(`${REACT_APP_SERVER_URL}webshop/v0/online-payments-settings`, data);
      return response.data;
    },
    onError: (error) => {
      if (error.response?.data?.errors) {
        console.error('Validation errors:', error.response.data.errors);
      } else {
        console.error('Update failed:', error.message);
      }

      enqueueSnackbar("Error during updating online payments settings!", 'error')
    },
  })

  const {
    onlinePayments: { maxItemsInCart },
  } = settings;

  const initialValues = {
    maxItemsInCart,
  };
  const validationSchema = globalOnlinePaymentsSettings;

  async function handleSubmit(values) {
    const { maxItemsInCart } = values;
    await mutate({
      maxItemsInCart: parseInt(maxItemsInCart, 10)
    });
  }

  return (
    <Card>
      <CardContent>
        <Typography variant="h3" component="h1" gutterBottom>
          Global Online Payments Settings
        </Typography>

        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          successText="Online payments settings have been saved!"
        >
          <FForm>
            <FTextField
              name="maxItemsInCart"
              type="number"
              label="Max items in a single cart (count)"
              variant="outlined"
              fullWidth
              margin="normal"
            />

            <Grid container direction="row" justify="flex-end" alignItems="flex-start">
              <Grid item>
                <SubmitButton isSubmitting={isLoading}>Save Changes</SubmitButton>
              </Grid>
            </Grid>
          </FForm>
        </Formik>
      </CardContent>
    </Card>
  );
}

GlobalOnlinePaymentsSettings.propTypes = {
  settings: Types.settings.isRequired,
};

export default GlobalOnlinePaymentsSettings;
